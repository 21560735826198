import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { getQueryParams } from '../../utils/getQueryParams';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const Next = ({ dpd, handleDpdChange, getResults }) => {
  useEffect(() => {
    console.log('🚀 ~ file: Next.js:12 ~ useEffect ~ dpd:', dpd);
  }, []);
  return (
    <Box
      sx={{ width: '100%' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {/* <TextField
        placeholder="Please define your response variable"
        value={dpd}
        onChange={handleDpdChange}
        fullWidth
        sx={{ width: '60%', marginTop: '1rem' }}
      /> */}
      <FormControl variant="filled" sx={{ backgroundColor: 'transparent', width: '50%' }}>
        <InputLabel id="demo-simple-select-label" sx={{}}>
          Please define your response variable
        </InputLabel>
        <Select id="demo-simple-select" value={dpd} label="Dpd" onChange={handleDpdChange}>
          <MenuItem value={'30+ Dpd'}>30+ Dpd</MenuItem>
          <MenuItem value={'60+ Dpd'}>60+ Dpd</MenuItem>
          <MenuItem value={'90+ Dpd'}>90+ Dpd</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: '2rem', padding: '1rem 1.5rem', borderRadius: '30px' }}
        onClick={getResults}
        disabled={!dpd}
      >
        {getQueryParams('product') === 'calibrate' ? 'Recalibrate' : 'Scorecard'}
      </Button>
    </Box>
  );
};

export default Next;
