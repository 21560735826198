import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { Upload } from '../../assets/icons/Upload';
import { useDropzone } from 'react-dropzone';
import AcceptedFile from './AcceptedFile';

const UploadFile = ({ text, files, setFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
      'text/xlsx': ['.xlsx'],
    },
  });

  const handleDeleteClick = () => setFiles([]);

  return (
    <>
      {files?.length ? (
        <>
          {files?.map((file, index) => (
            <AcceptedFile name={file.name} key={index} handleDeleteClick={handleDeleteClick} />
          ))}
        </>
      ) : (
        <Box
          {...getRootProps()}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: 'rgba(246, 249, 253, 0.05)',
            border: '1px solid rgba(246, 249, 253, 0.2)',
            height: { xs: '60px', sm: '130px' },
            width: '100%',
            borderRadius: '16px',
            marginTop: '0.5rem',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Upload sx={{ marginRight: '10px' }} />
          <Typography
            component="p"
            sx={{
              color: 'rgba(246, 249, 253, 1)',
              fontSize: { xs: '12px', sm: '16px' },
              fontWeight: '400',
              // width: { xs: '80%', sm: '100%' },
            }}
          >
            <Typography
              component="span"
              sx={{
                color: 'rgba(87, 38, 249, 1)',
                fontWeight: '500',
                textDecoration: 'underline',
                fontSize: { xs: '12px', sm: '16px' },
              }}
            >
              Click to upload {text}
            </Typography>
          </Typography>

          <input {...getInputProps()} />
        </Box>
      )}
    </>
  );
};

export default UploadFile;
