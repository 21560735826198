import { Grid, TextField, Box, InputAdornment, Button, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Profile } from '../../assets/icons/Profile';
import { Email } from '../../assets/icons/Email';
import { Phone } from '../../assets/icons/Phone';
import { Company } from '../../assets/icons/Company';
import { Brief } from '../../assets/icons/Briefcase';
import { getQueryParams } from '../../utils/getQueryParams';
import { useSnackbar } from 'notistack';
import { saveUserInfo } from '../../services';
import { useSelector } from '../../store/contexts';
import { SAVE_REQUEST_ID, SAVE_USER_INFO } from '../../store/actions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const initialState = {
  value: '',
  error: false,
  errorMessage: 'Required field',
};

const initialValues = (existingUserDetails) => {
  const state = {
    name: {
      ...initialState,
      placeholder: 'Full Name',
      regex: /[^A-Za-z ]/g,
      type: '',
      icon: <Profile />,
    },
    email: {
      ...initialState,
      placeholder: 'Buisness Email',
      // regex: /([-A-Za-z0-9_.])+@([-A-Za-z0-9_])+([.]{1})([A-Za-z]{2,4})$/g,
      regex: '',
      type: '',
      icon: <Email />,
    },
    company: {
      ...initialState,
      placeholder: 'Company Name',
      regex: '',
      type: '',
      icon: <Company />,
    },
    phone: {
      ...initialState,
      placeholder: 'Phone Number',
      regex: /[^\d/]/gi,
      type: '',
      icon: <Phone />,
      maxLength: 10,
    },
    designation: {
      ...initialState,
      placeholder: 'Designation',
      regex: '',
      type: '',
      icon: <Brief />,
      width: '50%',
    },
  };

  if (existingUserDetails) {
    console.log(existingUserDetails);
    Object.keys(existingUserDetails).forEach((key) => {
      if (key !== 'product') {
        state[key].value = existingUserDetails[key];
      }
    });
  }

  return state;
};

const Inputs = ({ handleNextClick }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useSelector();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [values, setValues] = useState(initialValues(state.userDetails));
  const matches = useMediaQuery('(max-width:600px)');

  const handleSubmit = async () => {
    const payload = Object.keys(values).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: values[curr].value,
      }),
      {},
    );

    // const payload = {
    //   name: 'Ritesh Patil',
    //   email: 'riteshsp2000@gmail.com',
    //   company: 'BharatPe',
    //   phone: '8208601505',
    //   designation: 'SDE',
    // };

    payload.product = getQueryParams('product') === 'calibrate' ? 'Calibration' : 'Scorecard';

    try {
      const { data } = await saveUserInfo(payload);
      dispatch({
        type: SAVE_USER_INFO,
        payload,
      });
      dispatch({
        type: SAVE_REQUEST_ID,
        payload: data.request_id,
      });
      handleNextClick();
    } catch (error) {
      enqueueSnackbar(error.meessage || 'Something went wrong, please try again', {
        variant: 'error',
      });
    }
  };

  const handleChange = (e, key, obj) => {
    let value;
    if (key == 'phone') {
      value = e;
    } else {
      value = e.target.value.replace(obj.regex, '');
    }

    console.log('🚀 ~ file: index.js:123 ~ handleChange ~ value:', value);
    if (obj.maxLength) {
      value = value.substring(0, obj.maxLength);
    }
    setValues((current) => ({
      ...current,
      [key]: {
        ...current[key],
        value,
        error: false,
        errorMessage: 'Required Field',
      },
    }));
  };

  const handleBlur = (key, obj) => {
    let details = {};

    if (
      values.email.value &&
      key === 'email' &&
      !/([-A-Za-z0-9_.])+@([-A-Za-z0-9_])+([.]{1})([A-Za-z]{2,4})$/g.test(values.email.value)
    ) {
      details.error = true;
      details.errorMessage = 'Invalid Email. Please enter the correct one';
    }

    if (!values[key].value) {
      details.error = true;
    }

    setValues((current) => ({
      ...current,
      [key]: {
        ...current[key],
        ...details,
      },
    }));
  };

  useEffect(() => {
    if (Object.values(values).reduce((acc, curr) => curr.value && acc, true)) {
      setButtonDisabled(false);
    }
  }, [values, setButtonDisabled]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box display={matches ? '' : 'flex'}>
          <Box
            sx={{
              width: { xs: '75vw', sm: '50vw' },
              height: { xs: '30vh', sm: '100wh' },
              backgroundColor: 'transparent',
              margin: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            {/* <video controls width="100%">
              <source
                src={
                  'https://youtu.be/Km47Gt_jDf0'
                }
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video> */}
            <iframe
              className="video"
              title="Youtube player"
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={
                getQueryParams('product') === 'scorecard'
                  ? `https://youtube.com/embed/Km47Gt_jDf0?autoplay=1`
                  : 'https://youtube.com/embed/lphwuZfhbHc?autoplay=1'
              }
            ></iframe>
          </Box>
          <Grid container spacing={2} sx={{ width: { xs: '80%', sm: '65%' }, margin: '2rem' }}>
            {Object.keys(values).map((key) => {
              const obj = values[key];
              return (
                <Grid
                  item
                  key={key}
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {key == 'phone' ? (
                    <Box sx={{ width: matches ? '100%' : '80%' }}>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={obj.value}
                        country={'in'}
                        onChange={(e) => handleChange(e, key, obj)}
                        inputStyle={{
                          backgroundColor: 'transparent',
                          color: 'white',
                          width: '100%',
                          height: '50px',
                          borderColor: 'rgba(246,249,253,0.2)',
                        }}
                        dropdownStyle={{
                          color: 'black',
                          backgroundColor: 'white',
                        }}
                        buttonStyle={{
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                        }}
                        containerStyle={{}}
                        searchPlaceholder="Phone Number"
                      />
                    </Box>
                  ) : (
                    <TextField
                      value={obj.value}
                      onChange={(e) => handleChange(e, key, obj)}
                      onBlur={() => handleBlur(key, obj)}
                      error={obj.error}
                      helperText={obj.error ? obj.errorMessage : ''}
                      fullWidth
                      placeholder={obj.placeholder}
                      sx={{ width: matches ? '100%' : '80%' }}
                      inputProps={{
                        type: 'text',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{obj.icon}</InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          disabled={buttonDisabled}
          variant="contained"
          onClick={handleSubmit}
          sx={{
            padding: '1rem 2.5rem',
            borderRadius: '30px',
            marginTop: '2rem',
            '&.Mui-disabled': {
              backgroundColor: 'rgba(87, 38, 249, 0.1)',
              color: 'white',
            },
            marginBottom: '1rem',
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default Inputs;
