import { Get, Post } from './request';

export const fetchTemplates = async (product) => Get('/v1/api/template', { product });

export const saveUserInfo = async (userInfo) => Post('/v1/api/user-info', userInfo);

export const postScorecardPerf = async (scorecardInfo) =>
  Post('/v1/api/scorecard', scorecardInfo, {
    'Content-Type': 'multipart/form-data',
  });

export const postCalibrationPerf = async (calibrationInfo) =>
  Post('/v1/api/calibrate', calibrationInfo, {
    'Content-Type': 'multipart/form-data',
  });

export const sendEmail = async (id) => {
  return Post(`/v1/api/download-report`, { request_id: id });
};
