import React, { useState } from 'react';
import Stepper from '../../components/Stepper';
import { useStepper } from '../../Hooks/useStepper';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import CubeLeft from '../../assets/cube-left-2.png';
import CubeRight from '../../assets/cube-right.png';
import Stage1 from './Stage1';
import Stage2 from './Stage2';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { Cross } from '../../assets/icons/Cross';
import { useNavigate } from 'react-router-dom';

const STEPS = ['Step 1', 'Step 2'];

const STAGES = {
  STAGE1: 'STAGE1',
  STAGE2: 'STAGE2',
};

const Calibrate = () => {
  const [stage, setStage] = useState(STAGES.STAGE1);

  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  const { activeStep, completed, handleComplete, handleBack } = useStepper(STEPS);

  const handleNextClick = () => {
    setStage(STAGES.STAGE2);
    handleComplete();
  };

  const handleBackClick = () => {
    setStage(STAGES.STAGE1);
    handleBack();
  };

  const handleCancelClick = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        padding: { xs: '2rem', sm: '6rem' },
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        sx={{
          minHeight: '100%',
          width: '100%',
          backgroundColor: 'rgba(21, 21, 21, 1)',
          borderRadius: '16px',
          position: 'relative',
          zIndex: '999',
        }}
      >
        <Box
          // display="flex"
          alignItems="center"
          sx={{
            marginTop: '2rem',
            width: { xs: '100%', sm: '90%' },
            display: { xs: 'flex', sm: 'flex' },
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'flex-start', sm: 'space-between' },
          }}
        >
          {stage === STAGES.STAGE1 ? (
            <div style={{ width: '42px', display: { xs: 'none', sm: 'flex' } }}></div>
          ) : (
            <IconButton
              onClick={handleBackClick}
              sx={{
                border: '1px solid rgba(246, 249, 253, 0.35)',
                borderRadius: '50%',
                marginLeft: { xs: '20px', sm: '0' },
                alignSelf: 'baseline',
              }}
            >
              <ChevronLeft />
            </IconButton>
          )}

          <Box sx={{ width: { xs: '70%', sm: '20%' } }}>
            <Stepper steps={STEPS} activeStep={activeStep} completed={completed} />
          </Box>

          <IconButton
            onClick={handleCancelClick}
            sx={{
              border: '1px solid rgba(246, 249, 253, 0.35)',
              borderRadius: '50%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <Cross />
          </IconButton>
        </Box>

        {stage === STAGES.STAGE1 ? <Stage1 handleNextClick={handleNextClick} /> : <Stage2 />}

        <Typography
          sx={{
            fontSize: { xs: '10px', sm: '18px' },
            fontWeight: '400',
            textAlign: 'center',
            color: 'rgba(246, 249, 253, 0.8)',
            marginBottom: '3rem',
            fontStyle: 'italic',
            paddingTop: { xs: '5px', sm: '0' },
          }}
        >
          Built by bankers, for bankers
        </Typography>
      </Box>

      <img
        src={CubeLeft}
        alt="left-cube"
        style={{ position: 'fixed', top: '6rem', left: '6rem', width: '15%', zIndex: 0 }}
      />
      <img
        src={CubeRight}
        alt="left-cube"
        style={{ position: 'fixed', bottom: '6rem', right: '6rem', width: '20%', zIndex: 0 }}
      />
    </Box>
  );
};

export default Calibrate;
