import React from 'react';
import LogoSmall from '../assets/logo-small.png';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import CubeLeft from '../assets/cube-left-2.png';
import CubeRight from '../assets/cube-right.png';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  //true is phone

  const handleGetScorecardClick = () => navigate('/get-data?product=scorecard');
  const handleCalibrateClick = () => navigate('/get-data?product=calibrate');

  return (
    <>
      <img
        src={CubeLeft}
        alt="left-cube"
        style={{ position: 'fixed', bottom: 0, left: 0, width: '25%', zIndex: 0 }}
      />
      <img
        src={CubeRight}
        alt="left-cube"
        style={{ position: 'fixed', top: 0, right: 0, width: '25%', zIndex: 0 }}
      />

      <Grid
        sx={{ width: '100vw', height: '100vh', position: 'relative' }}
        container
        columns={{ xs: 1, md: 12 }}
      >
        <Grid item xs={1} />
        <Grid item xs={10} sx={{ paddingTop: '2.5rem' }}>
          <Box
            sx={{ width: '100%', position: 'relative' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={LogoSmall}
              alt="Veefin"
              style={{
                width: matches ? '120px' : '250px',
                height: 'auto',
                position: 'absolute',
                transform: 'translate(0%, -50%)',
                top: matches ? 0 : '50%',
                left: 0,
              }}
            />

            <Typography
              sx={{
                fontSize: { xs: '1.5rem', sm: '2rem' },
                fontWeight: { xs: '500', sm: '600' },
                paddingTop: matches ? '5%' : '0',
                
              }}
            >
              Veefin's AI driven Scorecard Evaluator
            </Typography>

            <div />
          </Box>
        </Grid>
        <Grid item xs={1} />

        <Grid itexm xs={1}></Grid>
        <Grid item xs={10} sx={{ padding: { xs: '1rem', sm: '2rem' } }}>
          <Box
            sx={{
              display: { xs: '', sm: 'flex' },
              background:
                'linear-gradient(180deg, #161931 0%, rgba(17.97, 20.15, 35.06, 0.44) 56%, rgba(14, 16, 25, 0) 100%)',
              borderRadius: 12,
              paddingTop: { xs: '10%', sm: '2%' },
            }}
          >
            <Box
              sx={{
                borderRadius: '10px',

                padding: { xs: '1rem', sm: '3rem' },
                height: { xs: 'calc(100vh - 300px - 6rem)', sm: 'calc(100vh - 300px - 2rem)' },
                width: { xs: '100%', sm: '50%' },
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Typography sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, fontWeight: '500' }}>
                Credit Scorecard Evaluation
              </Typography>

              <Typography
                sx={{
                  textAlign: 'center',
                  width: '85%',
                  color: 'rgba(246, 249, 253, 0.8)',
                  fontSize: { xs: '1rem', sm: '1.2rem' },
                }}
              >
                Whether your credit scorecard is working fine? Check it out with just a few clicks
                using Veefin's Credit Scorecard Evaluator. <br />
                Evaluate the performance of your scorecard with Advanced Analytics and Machine
                Learning
              </Typography>

              <Button
                onClick={handleGetScorecardClick}
                variant="outlined"
                sx={{
                  borderColor: 'rgba(246, 249, 253, 0.35)',
                  padding: '0.5rem 0.6rem',
                  borderRadius: '50px',
                  marginRight: '1rem',
                  background:
                    'linear-gradient(180deg, #9AA4FF 0%, rgba(154.06, 164.16, 255, 0.80) 97%)',
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: 'rgba(246, 249, 253, 1)',
                    textTransform: 'capitalize',
                    background: '#151515',
                    boxShadow: '0px 4px 5px rgba(87.16, 37.92, 248.96, 0.60)',
                    borderRadius: 50,
                    border: '0.50px solid',
                    padding: '1rem 2.5rem',
                  }}
                >
                  Evaluate
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                borderRadius: '10px',
                // backgroundColor: 'rgba(0,0,0,0.35)',
                padding: { xs: '1rem', sm: '3rem' },
                height: { xs: 'calc(100vh - 300px - 6rem)', sm: 'calc(100vh - 300px - 2rem)' },
                width: { xs: '100%', sm: '50%' },
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Typography sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, fontWeight: '500' }}>
                Scorecard Recalibration
              </Typography>

              <Typography
                sx={{
                  textAlign: 'center',
                  width: '85%',
                  color: 'rgba(246, 249, 253, 0.8)',
                  fontSize: { xs: '1rem', sm: '1.2rem' },
                }}
              >
                Gone are the days, when we assign Scores and weights to the KPIs using Excel.
                <br />
                Recalibrate the Scores of the KPIs using AI-driven Veefin Scorecard Recalibration
                Tool.
              </Typography>

              <Button
                onClick={handleCalibrateClick}
                variant="outlined"
                sx={{
                  borderColor: 'rgba(246, 249, 253, 0.35)',
                  padding: '0.5rem 0.6rem',
                  borderRadius: '50px',
                  marginRight: '1rem',
                  background:
                    'linear-gradient(180deg, #9AA4FF 0%, rgba(154.06, 164.16, 255, 0.80) 97%)',
                  // ':hover': { padding: { xs: '1rem', sm: '1rem' } },
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: 'rgba(246, 249, 253, 1)',
                    textTransform: 'capitalize',
                    background: '#151515',
                    boxShadow: '0px 4px 5px rgba(87.16, 37.92, 248.96, 0.60)',
                    borderRadius: 50,
                    border: '0.50px solid',
                    padding: '1rem 2.5rem',
                  }}
                >
                  Recalibrate
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={5} sx={{ padding: '2rem' }}>
          <Box
            sx={{
              borderRadius: '10px',
              backgroundColor: 'rgba(0,0,0,0.35)',
              padding: { xs: '1rem', sm: '3rem' },
              height: { xs: 'calc(100vh - 300px - 6rem)', sm: 'calc(100vh - 300px - 2rem)' },
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, fontWeight: '500' }}>
              Scorecard Recalibration
            </Typography>

            <Typography
              sx={{
                textAlign: 'center',
                width: '85%',
                color: 'rgba(246, 249, 253, 0.8)',
                fontSize: { xs: '1rem', sm: '1.2rem' },
              }}
            >
              Gone are the days, when we assign Scores and weights to the KPIs using Excel.
              <br />
              Recalibrate the Scores of the KPIs using AI-driven Veefin Scorecard Recalibration
              Tool.
            </Typography>

            <Button
              onClick={handleCalibrateClick}
              variant="outlined"
              sx={{
                borderColor: 'rgba(246, 249, 253, 0.35)',
                padding: '1rem 3.5rem',
                borderRadius: '50px',
                marginRight: '1rem',
              }}
            >
              <Typography
                component="p"
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: 'rgba(246, 249, 253, 1)',
                  textTransform: 'capitalize',
                }}
              >
                Recalibrate
              </Typography>
            </Button>
          </Box>
        </Grid> */}
        <Grid itexm xs={1}></Grid>

        <Grid itexm xs={1}></Grid>
        <Grid itexm xs={10}>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px' },
              fontWeight: '400',
              textAlign: 'center',
              color: 'rgba(246, 249, 253, 0.8)',
              marginBottom: '3rem',
              fontStyle: 'italic',
            }}
          >
            Built by bankers, for bankers
          </Typography>
        </Grid>
        <Grid itexm xs={1}></Grid>
      </Grid>
    </>
  );
};

export default HomePage;
