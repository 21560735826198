import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const ChevronLeft = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_112_1112"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_112_1112)">
      <path
        d="M10.0861 12.0048L15.7129 17.4311C15.9043 17.6157 15.9968 17.8311 15.9904 18.0771C15.9841 18.3232 15.8852 18.5386 15.6938 18.7231C15.5024 18.9077 15.2791 19 15.0239 19C14.7687 19 14.5455 18.9077 14.3541 18.7231L8.45933 13.0568C8.30622 12.9092 8.19139 12.743 8.11483 12.5585C8.03828 12.3739 8 12.1893 8 12.0048C8 11.8202 8.03828 11.6356 8.11483 11.4511C8.19139 11.2665 8.30622 11.1004 8.45933 10.9527L14.3541 5.26793C14.5455 5.08335 14.7719 4.99415 15.0335 5.0003C15.2951 5.00645 15.5215 5.10181 15.7129 5.28638C15.9043 5.47095 16 5.68629 16 5.93238C16 6.17848 15.9043 6.39381 15.7129 6.57838L10.0861 12.0048Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'ChevronLeft',
);
