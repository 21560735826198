import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { GreenTick } from '../../assets/icons/GreenTick';
import { Delete } from '../../assets/icons/Delete';
import { ErrorALert } from '../../assets/icons/ErrorAlert';

const AcceptedFile = ({ type = 'success', handleDeleteClick, name }) => {
  return (
    <Box
      sx={{
        minWidth: { xs: '0px', sm: '450px' },
        padding: '1rem 1.5rem',
        backgroundColor: 'rgba(36, 44, 50, 1)',
        borderRadius: '12px',
        borderBottom: type === 'success' ? '3px solid rgb(0,225,123)' : '3px solid rgb(251,1,90)',
        marginTop: '1rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} container alignItems="center" justifyContent="flex-start">
          {type === 'success' ? (
            <GreenTick sx={{ width: '100%' }} />
          ) : (
            <ErrorALert sx={{ width: '100%' }} />
          )}
        </Grid>
        <Grid item xs={8}>
          <Typography
            component="h4"
            sx={{
              fontSize: { xs: '12px', sm: '16px' },
              fontWeight: '600',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            {name}
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: { xs: '10px', sm: '14px' },
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.75)',
            }}
          >
            Uploaded
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          container
          alignItems="center"
          justifyContent="center"
          onClick={handleDeleteClick}
        >
          <Delete
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AcceptedFile;
