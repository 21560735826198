import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';
import UploadFile from '../../components/UploadFile';
import { fetchTemplates, postCalibrationPerf, postScorecardPerf } from '../../services';
import { getQueryParams } from '../../utils/getQueryParams';
import { useSnackbar } from 'notistack';
import { getProductName } from '../../utils/getProductName';
import {
  SAVE_CALIBRATE_RESULTS,
  SAVE_CALIBRATE_TEMPLATE_DOWNLOADED,
  SAVE_SCORECARD_RESULTS,
  SAVE_SCORECARD_TEMPLATE_DOWNLOADED,
} from '../../store/actions';
import { useSelector } from '../../store/contexts';
import Next from './Next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { download_files } from '../../utils/downloadMultiplefiles';

const Stage2 = () => {
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [dpd, setDpd] = useState('');
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useSelector();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const getResults = async () => {
    setLoading(true);
    try {
      if (getQueryParams('product') === 'calibrate') {
        const formData = new FormData();
        formData.append('scorecard', file1[0]);
        formData.append('scorecard_data', file2[0]);
        formData.append('dpd', dpd);
        formData.append('request_id', state.requestId);

        const { data } = await postCalibrationPerf(formData);
        dispatch({
          type: SAVE_CALIBRATE_RESULTS,
          payload: data,
        });
        navigate('/results?product=calibrate');
      } else {
        const formData = new FormData();
        formData.append('scorecard_performance', file1[0]);
        formData.append('dpd', dpd);
        formData.append('request_id', state.requestId);

        const { data } = await postScorecardPerf(formData);
        dispatch({
          type: SAVE_SCORECARD_RESULTS,
          payload: data,
        });
        navigate('/results?product=scorecard');
      }
    } catch (error) {
      enqueueSnackbar(error.message || 'Something went wrong, please try again', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRequiredTemplates = async () => {
    try {
      const { data } = await fetchTemplates(getProductName(getQueryParams('product')));
      download_files(data);

      dispatch({
        type: getQueryParams('product')
          ? SAVE_CALIBRATE_TEMPLATE_DOWNLOADED
          : SAVE_SCORECARD_TEMPLATE_DOWNLOADED,
        payload: true,
      });
    } catch (error) {
      enqueueSnackbar('Failed downloading the templates', { variant: 'error' });
    }
  };

  const handleDpdChange = (e) => {
    console.log('🚀 ~ file: Stage2.js:85 ~ handleDpdChange ~ e:', e?.target?.value);
    const value = e?.target?.value;
    // .replace(/[^\d/]/gi, '');
    // console.log('🚀 ~ file: Stage2.js:87 ~ handleDpdChange ~ value:', value);
    setDpd(value);
  };

  useEffect(() => {
    // if (!state.templateDownloaded.scorecard && getQueryParams('product') === 'scorecard') {
    //   fetchRequiredTemplates();
    // }
    // if (!state.templateDownloaded.calibrate && getQueryParams('product') === 'calibrate') {
    //   fetchRequiredTemplates();
    // }
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ width: '100%' }}
    >
      <Typography
        sx={{
          marginTop: { xs: '0.5rem', sm: '2rem' },
          fontSize: { xs: '20px', sm: '36px' },
          fontWeight: '600',
          color: 'rgba(246, 249, 253, 1)',
          textAlign: 'center',
          width: { xs: '100%', sm: '70%' },
        }}
      >
        Great job! Now, please upload
        <br /> the completed template.
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: '13px', sm: '20px' },
          fontWeight: '400',
          color: 'rgba(246, 249, 253, 0.7)',
          marginTop: '1rem',
          textAlign: 'center',
          width: { xs: '90%', sm: '70%' },
        }}
      >
        Download Template, please{' '}
        <Typography
          component="span"
          sx={{
            color: 'rgba(87, 38, 249, 1)',
            fontWeight: '500',
            textDecoration: 'underline',
            '&:hover': { cursor: 'pointer' },
          }}
          onClick={fetchRequiredTemplates}
        >
          click here
        </Typography>
      </Typography>

      {getQueryParams('product') === 'calibrate' && (
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: 'rgba(246, 249, 253, 0.8)',
            marginTop: '1rem',
          }}
        >
          *After running the calibration, you will see a dashboard displaying the metrics.
        </Typography>
      )}

      <Alert
        icon={false}
        severity="warning"
        variant="filled"
        sx={{
          width: { xs: '90%', sm: '48%' },
          margin: '1rem auto',
          background: 'rgba(255, 210, 31, 0.15)',
          borderLeft: '3px solid rgba(255, 210, 31, 1)',
        }}
      >
        To get the performance of your scorecard, please upload data as mentioned in the specified
        format as per the template given
      </Alert>

      <Grid container spacing={2} sx={{ width: { xs: '100% !important', sm: '50% !important' } }}>
        {getQueryParams('product') === 'calibrate' ? (
          <>
            <Grid item xs={12}>
              <UploadFile text="scorecard template" files={file1} setFiles={setFile1} />
            </Grid>
            <Grid item xs={12}>
              <UploadFile text="scorecard data template" files={file2} setFiles={setFile2} />
            </Grid>
            {file1.length && file2.length ? (
              <Grid item xs={12}>
                <Next dpd={dpd} handleDpdChange={handleDpdChange} getResults={getResults} />
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <UploadFile text="scorecard template" files={file1} setFiles={setFile1} />
            {file1.length ? (
              <Next dpd={dpd} handleDpdChange={handleDpdChange} getResults={getResults} />
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>

      {loading && <Loader />}
    </Box>
  );
};

export default Stage2;
