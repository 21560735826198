import React, { useEffect, useState } from 'react';
import ResultsCard from '../components/ResultsCard';
import { Box, Container, Grid, Typography, useMediaQuery, Modal, Button } from '@mui/material';
import Header from '../components/Header';
import CubeLeft from '../assets/cube-left.png';
import { useSelector } from '../store/contexts';
import { getQueryParams } from '../utils/getQueryParams';
import { useNavigate } from 'react-router-dom';
import { EmailModal } from '../assets/icons/EmailModal';
import { EmailErrorModal } from '../assets/icons/EmailErrorModal';

const Results = () => {
  const [state] = useSelector();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  if (!state.results.scorecard.pdf_file_path && !state.results.calibrate.pdf_file_path) {
    // return setTimeout(() => navigate('/'), 0);
  }
  useEffect(() => {
    console.log('🚀 ~ file: Results.js:27 ~ useEffect ~ state:', state);
  }, []);

  return (
    <>
      <img
        src={CubeLeft}
        alt="left-cube"
        style={{ position: 'fixed', bottom: '0', left: 0, width: '25%', zIndex: 0 }}
      />

      <Container sx={{ zIndex: 1000, position: 'relative' }}>
        <Header handleErrorOpen={handleErrorOpen} handleOpen={handleOpen} />

        <Box
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: { xs: '1rem 0rem', sm: '4rem 0rem' },
            marginTop: '110px',
            borderBottom: '1px solid rgba(246, 249, 253, 0.12)',
            display: { xs: '', sm: 'flex' },
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: '24px', sm: '3rem' }, fontWeight: '600', color: 'white' }}
          >
            {getQueryParams('product') === 'calibrate'
              ? 'Your Credit Scorecard Performance'
              : 'Your Credit Scorecard Evaluation'}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '14px', sm: '20px' },
              fontWeight: '300',
              color: 'rgba(246, 249, 253, 0.7)',
              width: { xs: '100%', sm: '50%' },
              paddingTop: { xs: '10px', sm: '' },
            }}
          >
            Hi {state.userDetails.name}, here is your scorecard performance based on the data
            provided.{' '}
            {getQueryParams('product') === 'calibrate' ? (
              <span dangerouslySetInnerHTML={{ __html: state.results.calibrate.conclusion }}></span>
            ) : (
              ''
            )}
          </Typography>
        </Box>
        {getQueryParams('product') !== 'calibrate' &&
          state?.results?.scorecard?.roc_auc &&
          state?.results?.scorecard?.roc_auc < 0.7 && (
            <Box display={'flex'} justifyContent={'end'} alignSelf={'flex-end'}>
              <Button
                onClick={() => {
                  navigate('/get-data?product=calibrate');
                }}
                // sx={{

                //   padding: '1rem',
                //   borderRadius: 10,
                //   marginTop: 2,
                // }}

                color="primary"
                sx={{
                  margin: '1rem',
                  borderRadius: '30px',
                  borderColor: 'white',
                  backgroundColor: '#5726F9',
                  color: 'white',
                  padding: '10px 18px',
                  fontSize: { xs: '12px', sm: '14px' },
                }}
              >
                Recalibrate
              </Button>
            </Box>
          )}

        <Grid
          container={!matches}
          spacing={3}
          sx={{ marginTop: '2rem', backgroundColor: 'rgba(7, 9, 19, 1)' }}
        >
          {getQueryParams('product') === 'calibrate' ? (
            <>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: { xs: '14px', sm: '1.2rem' }, fontWeight: '600' }}>
                  Pre Recalibrated Scorecard Performance
                </Typography>
                <Box sx={{ width: { xs: '100%', sm: '70%' } }}>
                  <ResultsCard
                    title="AUC Value"
                    value={state.results.calibrate.pre_score_perf_info.roc_auc}
                    severity="excellent"
                  />
                  <ResultsCard
                    title="Gini Coefficient"
                    value={state.results.calibrate.pre_score_perf_info.gini}
                    severity="acceptable"
                  />
                  <ResultsCard
                    title="KS Static"
                    value={state.results.calibrate.pre_score_perf_info.ks}
                    severity="nonAcceptable"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '1.2rem' },
                    fontWeight: '600',
                    marginTop: { xs: '2%', sm: 0 },
                  }}
                >
                  Post Recalibrated Scorecard Performance
                </Typography>
                <Box sx={{ width: { xs: '100%', sm: '70%' } }}>
                  <ResultsCard
                    title="AUC Value"
                    value={state.results.calibrate.post_score_perf_info.roc_auc}
                    severity="excellent"
                  />

                  <ResultsCard
                    title="Gini Coefficient"
                    value={state.results.calibrate.post_score_perf_info.gini}
                    severity="acceptable"
                  />

                  <ResultsCard
                    title="KS Static"
                    value={state.results.calibrate.post_score_perf_info.ks}
                    severity="nonAcceptable"
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={4}>
                <ResultsCard
                  title="AUC Value"
                  value={state.results.calibrate.pre_score_perf_info.roc_auc}
                  severity="excellent"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="Gini Coefficient"
                  value={state.results.calibrate.pre_score_perf_info.gini}
                  severity="acceptable"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="KS Static"
                  value={state.results.calibrate.pre_score_perf_info.ks}
                  severity="nonAcceptable"
                />
              </Grid> */}

              {/* <Grid item xs={12}>
                <Typography sx={{ fontSize: '1.2rem', fontWeight: '600' }}>
                  Post Calibrated Scorecard Performance
                </Typography>
              </Grid> */}
              {/* <Grid item xs={4}>
                <ResultsCard
                  title="AUC Value"
                  value={state.results.calibrate.post_score_perf_info.roc_auc}
                  severity="excellent"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="Gini Coefficient"
                  value={state.results.calibrate.post_score_perf_info.gini}
                  severity="acceptable"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="KS Static"
                  value={state.results.calibrate.post_score_perf_info.ks}
                  severity="nonAcceptable"
                />
              </Grid> */}
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <ResultsCard
                  title="AUC Value"
                  value={state.results.scorecard.roc_auc}
                  severity="excellent"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="Gini Coefficient"
                  value={state.results.scorecard.gini_value}
                  severity="acceptable"
                />
              </Grid>
              <Grid item xs={4}>
                <ResultsCard
                  title="KS Static"
                  value={state.results.scorecard.ks_value}
                  severity="nonAcceptable"
                />
              </Grid>
            </>
          )}
        </Grid>

        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px' },
            fontWeight: '400',
            textAlign: 'center',
            color: 'rgba(246, 249, 253, 0.8)',
            marginTop: '4rem',
            fontStyle: 'italic',
          }}
        >
          Built by bankers, for bankers
        </Typography>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            boxShadow: '0px 4px 40px #5726F9',
            outline: 'none',
            borderRadius: 5,
          }}
        >
          <Box display="flex" justifyContent="center">
            <EmailModal sx={{ fontSize: '100px', textAlign: 'center', margin: '1%' }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            sx={{ fontSize: { xs: '16px', sm: '22px' }, margin: '2%' }}
          >
            Email Sent!
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            sx={{ mt: 2, fontSize: { xs: '12px', sm: '16px' } }}
          >
            We have successfully sent your performance scorecard to your Email Id.
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            boxShadow: '0px 4px 40px #8E0101',
            outline: 'none',
            borderRadius: 5,
          }}
        >
          <Box display="flex" justifyContent="center">
            <EmailErrorModal sx={{ fontSize: '100px', textAlign: 'center', margin: '1%' }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            sx={{ fontSize: { xs: '16px', sm: '22px' }, margin: '2%' }}
          >
            Error !
          </Typography>
          <Typography
            id="modal-modal-description"
            textAlign="center"
            sx={{ mt: 2, fontSize: { xs: '12px', sm: '16px' } }}
          >
            Error while sending an email. <br />
            Message not sent!
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Results;
