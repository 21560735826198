import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Brief = createSvgIcon(
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_201_100"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_201_100)">
      <path
        d="M4.8077 20.5C4.30257 20.5 3.875 20.325 3.525 19.975C3.175 19.625 3 19.1974 3 18.6923V8.30773C3 7.80259 3.175 7.37503 3.525 7.02503C3.875 6.67503 4.30257 6.50003 4.8077 6.50003H9V4.80775C9 4.30263 9.175 3.87508 9.525 3.52508C9.875 3.17508 10.3026 3.00008 10.8077 3.00008H14.1923C14.6974 3.00008 15.125 3.17508 15.475 3.52508C15.825 3.87508 16 4.30263 16 4.80775V6.50003H20.1923C20.6974 6.50003 21.125 6.67503 21.475 7.02503C21.825 7.37503 22 7.80259 22 8.30773V18.6923C22 19.1974 21.825 19.625 21.475 19.975C21.125 20.325 20.6974 20.5 20.1923 20.5H4.8077ZM4.8077 19H20.1923C20.2692 19 20.3397 18.968 20.4038 18.9039C20.4679 18.8397 20.5 18.7692 20.5 18.6923V8.30773C20.5 8.23079 20.4679 8.16027 20.4038 8.09615C20.3397 8.03205 20.2692 8 20.1923 8H4.8077C4.73077 8 4.66024 8.03205 4.59613 8.09615C4.53202 8.16027 4.49998 8.23079 4.49998 8.30773V18.6923C4.49998 18.7692 4.53202 18.8397 4.59613 18.9039C4.66024 18.968 4.73077 19 4.8077 19ZM10.5 6.50003H14.5V4.80775C14.5 4.73082 14.4679 4.6603 14.4038 4.5962C14.3397 4.53209 14.2692 4.50003 14.1923 4.50003H10.8077C10.7308 4.50003 10.6602 4.53209 10.5961 4.5962C10.532 4.6603 10.5 4.73082 10.5 4.80775V6.50003Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'Brief',
);
