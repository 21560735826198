import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const EmailErrorModal = createSvgIcon(
  <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="41" cy="41" r="41" fill="#FC6D87" fill-opacity="0.19" />
    <circle cx="41" cy="41" r="33" fill="#B30020" />
    <path
      d="M39.666 45H42.3327V47.6667H39.666V45ZM39.666 34.3333H42.3327V42.3333H39.666V34.3333ZM40.9993 27.6667C33.626 27.6667 27.666 33.6667 27.666 41C27.666 44.5362 29.0708 47.9276 31.5713 50.4281C32.8094 51.6662 34.2792 52.6483 35.8969 53.3184C37.5146 53.9885 39.2484 54.3333 40.9993 54.3333C44.5356 54.3333 47.927 52.9286 50.4274 50.4281C52.9279 47.9276 54.3327 44.5362 54.3327 41C54.3327 39.249 53.9878 37.5152 53.3177 35.8976C52.6477 34.2799 51.6656 32.81 50.4274 31.5719C49.1893 30.3338 47.7195 29.3517 46.1018 28.6816C44.4841 28.0115 42.7503 27.6667 40.9993 27.6667ZM40.9993 51.6667C38.1704 51.6667 35.4573 50.5429 33.4569 48.5425C31.4565 46.5421 30.3327 43.829 30.3327 41C30.3327 38.171 31.4565 35.4579 33.4569 33.4575C35.4573 31.4571 38.1704 30.3333 40.9993 30.3333C43.8283 30.3333 46.5414 31.4571 48.5418 33.4575C50.5422 35.4579 51.666 38.171 51.666 41C51.666 43.829 50.5422 46.5421 48.5418 48.5425C46.5414 50.5429 43.8283 51.6667 40.9993 51.6667Z"
      fill="#F8F8F8"
    />
  </svg>,
  'EmailErrorModal',
);
