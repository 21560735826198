import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const GreenTick = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_112_1254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_112_1254)">
      <path
        d="M14.067 18.8222L10.9337 15.6889C10.7188 15.4741 10.4522 15.3667 10.1337 15.3667C9.81515 15.3667 9.54107 15.4815 9.31143 15.7111C9.09663 15.9259 8.98923 16.1926 8.98923 16.5111C8.98923 16.8296 9.09663 17.0963 9.31143 17.3111L13.2892 21.3111C13.5114 21.5333 13.7707 21.6444 14.067 21.6444C14.3633 21.6444 14.6225 21.5333 14.8448 21.3111L22.667 13.4889C22.8966 13.2593 23.0114 12.9852 23.0114 12.6667C23.0114 12.3482 22.8966 12.0741 22.667 11.8444C22.4374 11.6296 22.1596 11.5259 21.8337 11.5333C21.5077 11.5407 21.2374 11.6518 21.0226 11.8667L14.067 18.8222ZM16.0003 29.3333C14.1707 29.3333 12.4448 28.9833 10.8226 28.2833C9.20034 27.5833 7.78551 26.6296 6.57809 25.4222C5.37069 24.2148 4.41699 22.8 3.71699 21.1778C3.01699 19.5555 2.66699 17.8296 2.66699 16C2.66699 14.1556 3.01699 12.4222 3.71699 10.8C4.41699 9.17778 5.37069 7.76667 6.57809 6.56667C7.78551 5.36667 9.20034 4.41667 10.8226 3.71667C12.4448 3.01667 14.1707 2.66667 16.0003 2.66667C17.8448 2.66667 19.5781 3.01667 21.2003 3.71667C22.8225 4.41667 24.2337 5.36667 25.4337 6.56667C26.6337 7.76667 27.5837 9.17778 28.2837 10.8C28.9837 12.4222 29.3337 14.1556 29.3337 16C29.3337 17.8296 28.9837 19.5555 28.2837 21.1778C27.5837 22.8 26.6337 24.2148 25.4337 25.4222C24.2337 26.6296 22.8225 27.5833 21.2003 28.2833C19.5781 28.9833 17.8448 29.3333 16.0003 29.3333Z"
        fill="#00BC6B"
      />
    </g>
  </svg>,
  'GreenTick',
);
