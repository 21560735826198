import { Box, Typography } from '@mui/material';
import React from 'react';
import LoaderGif from '../../assets/loader-white.gif';

const Loader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        width: { xs: '100%', sm: 'calc(100vw - 12rem)' },
        height: { xs: '100%', sm: 'calc(100vh - 12rem)' },
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'rgba(87, 38, 249, 1)',
        borderRadius: '16px',
      }}
    >
      <img src={LoaderGif} alt="loader" style={{ width: '150px', height: '150px' }} />

      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '20px',
          marginTop: '1rem',
          color: 'rgba(246, 249, 253, 1)',
        }}
      >
        Preparing your results
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '14px',
          marginTop: '0.5rem',
          color: 'rgba(246, 249, 253, 0.75)',
          textAlign: 'center',
        }}
      >
        Please hold on as we analyze the data you <br /> provided and generate the results
      </Typography>
    </Box>
  );
};

export default Loader;
