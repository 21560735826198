import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Company = createSvgIcon(
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_201_55"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_201_55)">
      <path
        d="M5.2692 20.5769C4.8474 20.5769 4.48875 20.4291 4.19325 20.1336C3.89775 19.8381 3.75 19.4795 3.75 19.0577V8.59613C3.75 8.17434 3.89775 7.81569 4.19325 7.52018C4.48875 7.22468 4.8474 7.07693 5.2692 7.07693H7.75V4.59613C7.75 4.17434 7.89775 3.81569 8.19325 3.52018C8.48875 3.22468 8.8474 3.07693 9.2692 3.07693H15.7308C16.1526 3.07693 16.5112 3.22468 16.8067 3.52018C17.1022 3.81569 17.25 4.17434 17.25 4.59613V11.0769H19.7308C20.1526 11.0769 20.5112 11.2247 20.8067 11.5202C21.1022 11.8157 21.25 12.1743 21.25 12.5961V19.0577C21.25 19.4795 21.1022 19.8381 20.8067 20.1336C20.5112 20.4291 20.1526 20.5769 19.7308 20.5769H13.75V16.5769H11.25V20.5769H5.2692ZM5.24995 19.0769H7.75V16.5769H5.24995V19.0769ZM5.24995 15.0769H7.75V12.5769H5.24995V15.0769ZM5.24995 11.0769H7.75V8.5769H5.24995V11.0769ZM9.24995 15.0769H11.75V12.5769H9.24995V15.0769ZM9.24995 11.0769H11.75V8.5769H9.24995V11.0769ZM9.24995 7.07693H11.75V4.5769H9.24995V7.07693ZM13.25 15.0769H15.75V12.5769H13.25V15.0769ZM13.25 11.0769H15.75V8.5769H13.25V11.0769ZM13.25 7.07693H15.75V4.5769H13.25V7.07693ZM17.25 19.0769H19.75V16.5769H17.25V19.0769ZM17.25 15.0769H19.75V12.5769H17.25V15.0769Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'Company',
);
