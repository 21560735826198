import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

const CustomStepper = ({ steps, activeStep, completed }) => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step
            sx={{
              '& .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed': {
                color: 'rgba(0, 188, 107, 0.3) !important',
                border: '1px solid rgba(0, 188, 107, 1)',
              },
              '& .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-active': {
                color: 'rgba(0, 188, 107, 0.3) !important',
                border: '1px solid rgba(0, 188, 107, 1)',
              },
              '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
                color: 'transparent',
                border: '1px solid white',
                borderRadius: '50%',
              },
            }}
            key={label}
            completed={completed[index]}
          >
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
