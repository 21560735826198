import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const EmailModal = createSvgIcon(
  <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="41" cy="41" r="41" fill="#7583FF" />
    <circle cx="41" cy="41" r="33" fill="#5726F9" />
    <path
      d="M54.2778 29H26.7222C25.7711 29 25 29.7675 25 30.7143V51.2857C25 52.2325 25.7711 53 26.7222 53H54.2778C55.2289 53 56 52.2325 56 51.2857V30.7143C56 29.7675 55.2289 29 54.2778 29Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path d="M25 32L40.5 41L56 32" stroke="white" stroke-width="2" stroke-linecap="round" />
  </svg>,
  'EmailModal',
);
