import {
  SAVE_CALIBRATE_RESULTS,
  SAVE_CALIBRATE_TEMPLATE_DOWNLOADED,
  SAVE_REQUEST_ID,
  SAVE_SCORECARD_RESULTS,
  SAVE_SCORECARD_TEMPLATE_DOWNLOADED,
  SAVE_TEMPLATE_DOWNLOADED,
  SAVE_USER_INFO,
} from './actions';

export function reducer(state, action) {
  switch (action.type) {
    case SAVE_USER_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    case SAVE_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload,
      };
    case SAVE_CALIBRATE_TEMPLATE_DOWNLOADED:
      return {
        ...state,
        templateDownloaded: {
          calibrate: action.payload,
        },
      };
    case SAVE_SCORECARD_TEMPLATE_DOWNLOADED:
      return {
        ...state,
        templateDownloaded: {
          scorecard: action.payload,
        },
      };
    case SAVE_SCORECARD_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          scorecard: action.payload,
        },
      };
    case SAVE_CALIBRATE_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          calibrate: action.payload,
        },
      };
    default:
      return state;
  }
}
