import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Upload = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_112_1284"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_112_1284)">
      <path
        d="M6.3077 19.5C5.80257 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V15.7308C4.5 15.5179 4.5718 15.3397 4.7154 15.1962C4.85898 15.0526 5.03718 14.9808 5.25 14.9808C5.46282 14.9808 5.64102 15.0526 5.7846 15.1962C5.92818 15.3397 5.99997 15.5179 5.99997 15.7308V17.6923C5.99997 17.7692 6.03202 17.8397 6.09612 17.9039C6.16024 17.968 6.23077 18 6.3077 18H17.6922C17.7692 18 17.8397 17.968 17.9038 17.9039C17.9679 17.8397 18 17.7692 18 17.6923V15.7308C18 15.5179 18.0718 15.3397 18.2154 15.1962C18.3589 15.0526 18.5371 14.9808 18.75 14.9808C18.9628 14.9808 19.141 15.0526 19.2845 15.1962C19.4281 15.3397 19.5 15.5179 19.5 15.7308V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6922 19.5H6.3077ZM11.25 7.38843L9.32692 9.3115C9.17821 9.46022 9.00161 9.53362 8.79712 9.5317C8.59264 9.52977 8.41283 9.45124 8.2577 9.29613C8.11283 9.14099 8.03784 8.96536 8.03272 8.76923C8.02759 8.57308 8.10258 8.39744 8.2577 8.2423L11.3673 5.13273C11.4609 5.03915 11.5596 4.97312 11.6634 4.93465C11.7673 4.89619 11.8795 4.87695 12 4.87695C12.1205 4.87695 12.2327 4.89619 12.3365 4.93465C12.4403 4.97312 12.5391 5.03915 12.6327 5.13273L15.7422 8.2423C15.891 8.39102 15.9644 8.56505 15.9624 8.7644C15.9605 8.96375 15.8871 9.14099 15.7422 9.29613C15.5871 9.45124 15.4089 9.53137 15.2077 9.5365C15.0064 9.54164 14.8282 9.46664 14.673 9.3115L12.7499 7.38843V15.0385C12.7499 15.2513 12.6782 15.4295 12.5346 15.5731C12.391 15.7167 12.2128 15.7885 12 15.7885C11.7872 15.7885 11.609 15.7167 11.4654 15.5731C11.3218 15.4295 11.25 15.2513 11.25 15.0385V7.38843Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'Upload',
);
