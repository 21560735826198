import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Home = createSvgIcon(
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.83717 0.274491C7.01298 0.0987344 7.2514 0 7.5 0C7.7486 0 7.98702 0.0987344 8.16283 0.274491L14.7255 6.83708C14.8566 6.96819 14.9458 7.13522 14.982 7.31706C15.0182 7.49889 14.9996 7.68736 14.9286 7.85864C14.8577 8.02993 14.7376 8.17633 14.5834 8.27935C14.4293 8.38237 14.2481 8.43737 14.0627 8.43741H13.1252V14.0625C13.1252 14.3111 13.0264 14.5496 12.8506 14.7254C12.6747 14.9012 12.4363 15 12.1876 15H10.3126C10.0639 15 9.82547 14.9012 9.64964 14.7254C9.47383 14.5496 9.37505 14.3111 9.37505 14.0625V11.2499C9.37505 11.0013 9.27628 10.7628 9.10046 10.587C8.92464 10.4112 8.68617 10.3124 8.43753 10.3124H6.56248C6.31383 10.3124 6.07537 10.4112 5.89954 10.587C5.72372 10.7628 5.62495 11.0013 5.62495 11.2499V14.0625C5.62495 14.3111 5.52618 14.5496 5.35036 14.7254C5.17454 14.9012 4.93607 15 4.68743 15H2.81238C2.56373 15 2.32526 14.9012 2.14945 14.7254C1.97362 14.5496 1.87485 14.3111 1.87485 14.0625V8.43741H0.937325C0.751929 8.43737 0.570707 8.38237 0.416569 8.27935C0.262431 8.17633 0.142297 8.02993 0.0713541 7.85864C0.000411527 7.68736 -0.0181543 7.49889 0.0180037 7.31706C0.0541618 7.13522 0.14342 6.96819 0.274495 6.83708L6.83717 0.274491Z"
      fill="#F8F8F8"
    />
  </svg>,
  'Home',
);
