import React from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import Router from './router';
import { theme } from './utils/createTheme';
import { useSelector } from './store/contexts';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const [state] = useSelector();

  console.log('State: ', state);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <CssBaseline />
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
