import { Box, Card, Chip, Typography } from '@mui/material';
import React from 'react';

const colors = {
  excellent: {
    border: '#00BC6B',
    background: 'rgba(0, 188, 107, 0.05)',
    lightBackground: 'rgba(134, 134, 134, 0.19)',
    buttonBackground: 'rgba(0, 188, 107, 1)',
  },
  outstanding: {
    border: '#00BC6B',
    background: 'rgba(0, 188, 107, 0.05)',
    lightBackground: 'rgba(134, 134, 134, 0.19)',
    buttonBackground: 'rgba(0, 188, 107, 1)',
  },
  acceptable: {
    border: 'rgba(255, 210, 31, 0.6)',
    background: 'rgba(255, 210, 31, 0.05)',
    lightBackground: 'rgba(134, 134, 134, 0.19)',
    buttonBackground: 'rgba(205, 164, 0, 1)',
  },
  consideredLow: {
    border: 'rgba(251, 0, 90, 0.8)',
    background: 'rgba(251, 0, 90, 0.05)',
    lightBackground: 'rgba(134, 134, 134, 0.19)',
    buttonBackground: 'rgba(251, 0, 90, 1)',
  },
  nonAcceptable: {
    border: 'rgba(251, 0, 90, 0.8)',
    background: 'rgba(251, 0, 90, 0.05)',
    lightBackground: 'rgba(134, 134, 134, 0.19)',
    buttonBackground: 'rgba(251, 0, 90, 1)',
  },
};

const determineStatus = (title, value) => {
  switch (title) {
    case 'AUC Value':
      if (value < 0.7) return 'consideredLow';
      if (value < 0.8 && value >= 0.7) return 'acceptable';
      if (value < 0.9 && value >= 0.8) return 'excellent';
      if (value >= 0.9) return 'outstanding';
      return 'nonAcceptable';
    case 'Gini Coefficient':
      if (value >= 0.6) return 'acceptable';
      if (value < 0.6) return 'nonAcceptable';
      return 'acceptable';
    case 'KS Static':
      if (value >= 0.6) return 'acceptable';
      if (value < 0.6) return 'nonAcceptable';
      return 'acceptable';
    default:
      return 'acceptable';
  }
};

const getIndices = (title) => {
  switch (title) {
    case 'AUC Value':
      return {
        '> 0.9': 'Outstanding',
        '0.8 - 0.9': 'Excellent',
        '0.7 - 0.8': 'Acceptable',
        '< 0.7': 'Considered low',
      };
    case 'Gini Coefficient':
      return {
        '>= 0.6': 'Acceptable',
        '< 0.6': 'Not Acceptable',
      };
    case 'KS Static':
    default:
      return {
        '>= 0.6': 'Acceptable',
        '< 0.6': 'Not Acceptable',
      };
  }
};

const buttonText = {
  consideredLow: 'Considered Low',
  outstanding: 'Outstanding',
  acceptable: 'Acceptable',
  excellent: 'Excellent',
  nonAcceptable: 'Not Acceptable',
};

const ResultsCard = ({ title, value }) => {
  const severity = determineStatus(title, value);
  console.log(title, value, severity);

  return (
    <Card
      sx={{
        borderRadius: '16px',
        border: `1px solid ${colors[severity].border}`,
        backgroundColor: colors[severity].background,
        marginTop: { xs: '2%', sm: '4%' },
        padding: '16px',
      }}
    >
      <Box
        sx={{
          padding: '1rem',
          background: colors[severity].lightBackground,
          borderRadius: '16px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'rgb(199,201,202)',
            fontSize: { xs: '12px', sm: '14px' },
            fontWeight: '500',
            lineHeight: { xs: '14px', sm: '16px' },
          }}
        >
          {title}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingTop: '0.7rem',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: 'rgba(255, 255, 255, 1)',
              fontSize: { xs: '28px', sm: '32px' },
              fontWeight: 600,
            }}
          >
            {value}
          </Typography>

          {title === 'AUC Value' ? (
            <Chip
              label={buttonText[severity]}
              sx={{
                background: colors[severity].buttonBackground,
                color: 'rgba(255, 255, 255, 1)',
                fontSize: { xs: '12px', sm: '14px' },
                fontWeight: '500',
                borderRadius: '40px',
                padding: '10px 16px',
              }}
            />
          ) : null}
        </Box>
      </Box>

      <Box sx={{ marginTop: '1rem' }}>
        <Typography
          variant="body1"
          component="p"
          sx={{ fontSize: { xs: '14px', sm: '16px' }, fontWeight: '700' }}
        >
          Index
        </Typography>

        <ul className="custom-list">
          {Object.keys(getIndices(title)).map((key, index) => (
            <li key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: '14px', sm: '16px' },
                    fontWeight: '400',
                  }}
                >
                  {key}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: '14px', sm: '16px' },
                    fontWeight: '400',
                  }}
                >
                  {getIndices(title)[key]}
                </Typography>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </Card>
  );
};

export default ResultsCard;
