import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const ErrorALert = createSvgIcon(
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="13" fill="#FB005A" />
    <path
      d="M13 21.335C17.6033 21.335 21.335 17.6033 21.335 13C21.335 8.39675 17.6033 4.66504 13 4.66504C8.39675 4.66504 4.66504 8.39675 4.66504 13C4.66504 17.6033 8.39675 21.335 13 21.335Z"
      stroke="#242C32"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 9.66595V13"
      stroke="#242C32"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 16.3341H13.0054"
      stroke="#242C32"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>,
  'ErrorALert',
);
