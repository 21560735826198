import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Phone = createSvgIcon(
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_201_40"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_201_40)">
      <path
        d="M19.9403 20.5C18.0557 20.5 16.1625 20.0618 14.2606 19.1855C12.3586 18.3093 10.6112 17.073 9.01828 15.4769C7.42533 13.8807 6.19071 12.1333 5.31442 10.2346C4.43814 8.33591 4 6.44425 4 4.55965C4 4.2569 4.1 4.00461 4.3 3.80277C4.5 3.60094 4.75 3.50002 5.05 3.50002H8.3115C8.56407 3.50002 8.78683 3.5824 8.97977 3.74715C9.17272 3.9119 9.29548 4.11542 9.34803 4.35772L9.9211 7.3C9.96085 7.57308 9.95252 7.8077 9.8961 8.00385C9.8397 8.2 9.73842 8.36474 9.59225 8.49807L7.28265 10.7461C7.65445 11.4269 8.07913 12.0708 8.5567 12.6779C9.03427 13.2849 9.55125 13.8648 10.1077 14.4173C10.6564 14.966 11.2397 15.4757 11.8577 15.9462C12.4756 16.4167 13.1429 16.8545 13.8596 17.2596L16.1038 14.9962C16.2602 14.8334 16.4497 14.7193 16.6721 14.6539C16.8945 14.5885 17.1256 14.5725 17.3654 14.6058L20.1423 15.1712C20.3948 15.2378 20.6009 15.3667 20.7605 15.5577C20.9201 15.7487 21 15.9654 21 16.2077V19.45C21 19.75 20.899 20 20.6972 20.2C20.4954 20.4 20.2431 20.5 19.9403 20.5ZM6.57305 9.32695L8.35768 7.61925C8.38973 7.5936 8.41056 7.55834 8.42018 7.51347C8.42979 7.46859 8.42819 7.42692 8.41538 7.38847L7.98075 5.15385C7.96793 5.10257 7.9455 5.06411 7.91345 5.03847C7.8814 5.01282 7.83973 5 7.78845 5H5.64997C5.61152 5 5.57948 5.01282 5.55383 5.03847C5.52818 5.06411 5.51535 5.09616 5.51535 5.13462C5.56663 5.81796 5.67849 6.51219 5.85092 7.21732C6.02337 7.92246 6.26408 8.62567 6.57305 9.32695ZM15.273 17.9692C15.9359 18.2782 16.6272 18.5144 17.3471 18.6779C18.067 18.8413 18.7397 18.9385 19.3654 18.9692C19.4038 18.9692 19.4359 18.9564 19.4615 18.9308C19.4872 18.9051 19.5 18.8731 19.5 18.8346V16.7308C19.5 16.6795 19.4872 16.6378 19.4615 16.6057C19.4359 16.5737 19.3974 16.5513 19.3461 16.5384L17.2461 16.1115C17.2077 16.0987 17.174 16.0971 17.1452 16.1067C17.1163 16.1163 17.0859 16.1372 17.0538 16.1692L15.273 17.9692Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'Phone',
);
