export const initialState = {
  userDetails: null,
  product: null,
  requestId: null,
  results: {
    scorecard: {},
    calibrate: {},
  },
  templateDownloaded: false,
};

export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const SAVE_REQUEST_ID = 'SAVE_REQUEST_ID';
export const SAVE_TEMPLATE_DOWNLOADED = 'SAVE_TEMPLATE_DOWNLOADED';
export const SAVE_SCORECARD_TEMPLATE_DOWNLOADED = 'SAVE_SCORECARD_TEMPLATE_DOWNLOADED';
export const SAVE_CALIBRATE_TEMPLATE_DOWNLOADED = 'SAVE_CALIBRATE_TEMPLATE_DOWNLOADED';
export const SAVE_SCORECARD_RESULTS = 'SAVE_SCORECARD_RESULTS';
export const SAVE_CALIBRATE_RESULTS = 'SAVE_CALIBRATE_RESULTS';
