import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Email = createSvgIcon(
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_201_13"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_201_13)">
      <path
        d="M12.5 21.5C11.1858 21.5 9.95082 21.2506 8.795 20.752C7.63917 20.2533 6.63375 19.5766 5.77875 18.7217C4.92375 17.8669 4.24688 16.8616 3.74812 15.706C3.24937 14.5504 3 13.3156 3 12.0017C3 10.6877 3.24933 9.45271 3.748 8.29657C4.24667 7.14042 4.92342 6.13474 5.77825 5.27953C6.6331 4.42429 7.63834 3.74723 8.79398 3.24835C9.94959 2.74947 11.1844 2.50002 12.4983 2.50002C13.8122 2.50002 15.0473 2.7494 16.2034 3.24815C17.3596 3.7469 18.3652 4.42378 19.2205 5.27878C20.0757 6.13378 20.7527 7.13919 21.2516 8.29503C21.7505 9.45084 22 10.6858 22 12V13.2192C22 14.132 21.6866 14.907 21.0598 15.5442C20.433 16.1814 19.6631 16.5 18.75 16.5C18.1602 16.5 17.6134 16.3557 17.1096 16.0673C16.6057 15.7788 16.2077 15.382 15.9154 14.8769C15.4833 15.3923 14.9727 15.7916 14.3836 16.075C13.7945 16.3583 13.1666 16.5 12.5 16.5C11.2513 16.5 10.1891 16.0622 9.31345 15.1865C8.43782 14.3109 8 13.2487 8 12C8 10.7513 8.43782 9.68913 9.31345 8.81348C10.1891 7.93784 11.2513 7.50002 12.5 7.50002C13.7487 7.50002 14.8109 7.93784 15.6865 8.81348C16.5621 9.68913 17 10.7513 17 12V13.2192C17 13.7102 17.1689 14.1298 17.5067 14.4779C17.8445 14.826 18.2589 15 18.75 15C19.241 15 19.6554 14.826 19.9933 14.4779C20.3311 14.1298 20.5 13.7102 20.5 13.2192V12C20.5 9.76667 19.725 7.875 18.175 6.325C16.625 4.775 14.7333 4 12.5 4C10.2666 4 8.37498 4.775 6.82498 6.325C5.27498 7.875 4.49998 9.76667 4.49998 12C4.49998 14.2333 5.27498 16.125 6.82498 17.675C8.37498 19.225 10.2666 20 12.5 20H16.75C16.9625 20 17.1406 20.0719 17.2844 20.2157C17.4281 20.3595 17.5 20.5377 17.5 20.7503C17.5 20.9629 17.4281 21.141 17.2844 21.2846C17.1406 21.4282 16.9625 21.5 16.75 21.5H12.5ZM12.5 15C13.3333 15 14.0416 14.7083 14.625 14.125C15.2083 13.5417 15.5 12.8333 15.5 12C15.5 11.1667 15.2083 10.4583 14.625 9.875C14.0416 9.29167 13.3333 9 12.5 9C11.6666 9 10.9583 9.29167 10.375 9.875C9.79164 10.4583 9.49998 11.1667 9.49998 12C9.49998 12.8333 9.79164 13.5417 10.375 14.125C10.9583 14.7083 11.6666 15 12.5 15Z"
        fill="#F6F9FD"
      />
    </g>
  </svg>,
  'Email',
);
