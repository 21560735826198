import { Box, Button, Container, useMediaQuery } from '@mui/material';
import React from 'react';
import Logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/contexts';
import { getQueryParams } from '../../utils/getQueryParams';
import { Download } from '../../assets/icons/Download';
import { Home } from '../../assets/icons/Home';
import { sendEmail } from '../../services';
const Header = (props) => {
  const navigate = useNavigate();
  const [state] = useSelector();
  const matches = useMediaQuery('(max-width:600px)');

  const handleNavigateToHomepage = () => navigate('/');

  const getPdfLink = () => {
    return getQueryParams('product') === 'calibrate'
      ? state.results.calibrate.pdf_file_path
      : state.results.scorecard.pdf_file_path;
  };

  const downloadPdf = async () => {
    // const temporaryDownloadLink = document.createElement('a');
    // temporaryDownloadLink.style.display = 'none';
    // document.body.appendChild(temporaryDownloadLink);
    // temporaryDownloadLink.setAttribute('href', getPdfLink());
    // temporaryDownloadLink.setAttribute('download', 'results.pdf');
    // temporaryDownloadLink.click();
    // document.body.removeChild(temporaryDownloadLink);

    try {
      await sendEmail(state.requestId);
      props.handleOpen();
    } catch (err) {
      props.handleErrorOpen();
    }
  };

  return (
    <Box
      sx={{
        background: 'rgba(7, 9, 19, 1)',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100px',
          width: '100%',
          borderBottom: '1px solid rgba(246, 249, 253, 0.12)',
        }}
      >
        <img
          src={Logo}
          style={{ width: matches ? '130px' : '250px', height: 'auto' }}
          alt="Veefin"
        />

        <Box sx={{ display: { xs: 'flex', sm: '' }, justifyContent: { xs: 'flex-end', sm: '' } }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              marginRight: '1rem',
              borderRadius: '30px',
              borderColor: 'white',
              color: 'white',
              padding: '10px 18px',
            }}
            onClick={handleNavigateToHomepage}
          >
            {matches ? <Home sx={{ fontSize: '18px' }} /> : 'Go to homepage'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '30px', padding: '10px 18px' }}
            onClick={downloadPdf}
          >
            {matches ? <Download sx={{ fontSize: '18px' }} /> : 'Download Detailed report'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
