import axios from 'axios';

const Request = axios.create({
  baseURL: 'https://scorecardevaluator.com/scorecard',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'PgHiMNG0XlV$8$lTH$Z^28kzQo7ybLsj@yM&dqjYcPRmsFZN8&GmvXnFnlFqgFuV',
  },
});

const extractor = (response) => {
  const { data } = response;

  if (!data?.success) {
    throw new Error(data?.message || 'Something went wrong');
  }

  return data;
};

const onErrorInterceptor = (error) => {
  const { response } = error;

  if (!response) throw error;

  const { data } = response;

  if (!data.success) {
    throw new Error(data.message || 'Something went wrong');
  }
};

Request.interceptors.response.use(undefined, onErrorInterceptor);

export const Get = (path, params, headers) =>
  Request.get(path, { params, headers }).then(extractor);

export const Post = (path, payload, headers) =>
  Request.post(path, payload, { headers }).then(extractor);

export const Put = (path, payload, headers) =>
  Request.put(path, payload, { headers }).then(extractor);
