import { createTheme, outlinedInputClasses } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(87, 38, 249, 1)',
    },
    secondary: {
      main: 'rgba(87, 38, 249, 1)',
    },
    background: {
      default: 'rgba(7, 9, 19, 1)',
      paper: 'rgba(21, 21, 21, 1)',
      primary: 'rgba(7, 9, 19, 1)',
      secondary: 'rgba(21, 21, 21, 1)',
      tertiary: 'rgba(21, 21, 21, 1)',
    },
    text: {
      primary: 'rgba(246, 249, 253, 1)',
      secondary: 'rgba(246, 249, 253, 1)',
      tertiary: 'rgba(87, 38, 249, 1)',
      disabled: '#6C757D',
      button: 'rgba(246, 249, 253, 1)',
    },
    divider: '',
    neutral: {
      100: '#FFFFFF',
      200: '#F3F3F4',
      300: '#E7E8E9',
      400: '#CFD1D4',
      500: '#9FA3A9',
      600: '#6F757E',
      700: '#3F4753',
      800: '#27303E',
      900: '#0F1928',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': 'rgba(246, 249, 253, 0.2)',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': '#6F7E8C',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
  },
});
