import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

export const Delete = createSvgIcon(
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.21875 1.0625C4.375 0.71875 4.71875 0.5 5.09375 0.5H8.875C9.25 0.5 9.59375 0.71875 9.75 1.0625L10 1.5H13C13.5312 1.5 14 1.96875 14 2.5C14 3.0625 13.5312 3.5 13 3.5H1C0.4375 3.5 0 3.0625 0 2.5C0 1.96875 0.4375 1.5 1 1.5H4L4.21875 1.0625ZM12.3125 15.0938C12.2812 15.9062 11.625 16.5 10.8125 16.5H3.15625C2.34375 16.5 1.6875 15.9062 1.65625 15.0938L0.96875 4.5H13L12.3125 15.0938Z"
      fill="#F6F9FD"
    />
  </svg>,
  'Delete',
);
