import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Results from './pages/Results';
import GetData from './pages/GetData';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/results',
    element: <Results />,
  },
  {
    path: '/get-data',
    element: <GetData />,
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
