import React from 'react';
import { Box, Typography } from '@mui/material';
import Inputs from '../../components/Inputs';

const Stage1 = ({ handleNextClick }) => {
  return (
    <Box>
      <Typography
        sx={{
          marginTop: '2rem',
          fontSize: { xs: '20px', sm: '36px' },
          fontWeight: '600',
          color: 'rgba(246, 249, 253, 1)',
          textAlign: 'center',
        }}
      >
        Give your underwriting <br /> process on edge
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: '13px', sm: '20px' },
          fontWeight: '400',
          color: 'rgba(246, 249, 253, 0.7)',
          marginTop: '1rem',
          textAlign: 'center',
        }}
      >
        Let's get started !
      </Typography>

      <Inputs handleNextClick={handleNextClick} />
    </Box>
  );
};

export default Stage1;
