import React, { useReducer, useMemo, createContext, useContext } from 'react';

import { reducer } from './reducers';
import { initialState } from './actions';

const Context = createContext(initialState);

export function StateProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <Context.Provider value={value} {...props} />;
}

export function useSelector() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCompose must be used within a ComposeProvider');
  }

  const [state, dispatch] = context;
  return [state, dispatch];
}
